/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Gravatar from "react-gravatar"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author
          social {
            github 
          }
          gravator
        }
      }
    }
  `)

  const { author, social, gravator } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >
      <Gravatar email={gravator} />
      <p>
        Written by <strong>{author}</strong> who lives and works in Shibuya, Tokyo. 
        {` `}
        <a href={`https://github.com/${social.github}`}>
        Github Account
        </a>
      </p>
    </div>
  )
}

export default Bio
